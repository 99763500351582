import React from "react";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { PrimaryText } from "components/Common/PrimaryText";
import { Archive } from "assets/svgs";
import { COLORS } from "constants/Colors";
import { TouchableOpacity } from "react-native-web";
import moment from "moment";
import { dollarFormatter } from "utils/helpers";
import { FiTrash, FiTrash2 } from "react-icons/fi";

export default function CustomLinksCard({
	id,
	name,
	customUrl,
	createdAt,
	onInactiveLink,
	selectedOrg,
	totalDonations = 0,
	backers = [],
	onPress
}) {
	

	return (
		<TableRow>
			<TableCell>
				<TouchableOpacity
					onPress={onPress}
				>
					<PrimaryText
						fontSize={20}
						fontWeight={900}
					>
						{name}
					</PrimaryText>
				</TouchableOpacity>
			</TableCell>
			<TableCell>
				<PrimaryText
					fontSize={16}
					fontWeight={500}
					color={COLORS.primary.lightBlue}
					style={{ textDecoration: "underline" }}
				>
					{`dev.donate.rayzeapp.com/${selectedOrg?.customUrl ? selectedOrg?.customUrl : "orgs/" + selectedOrg?.id}/${customUrl}`}
				</PrimaryText>
			</TableCell>
			<TableCell>
				<PrimaryText
					fontSize={16}
					fontWeight={500}
				>
					{createdAt ? moment(createdAt.toDate()).format("MMM/DD/YYYY") : ""}
				</PrimaryText>
			</TableCell>
			<TableCell>
				<PrimaryText
					fontSize={16}
					fontWeight={500}
				>
					{backers?.length || 0}
				</PrimaryText>
			</TableCell>
			<TableCell>
				<PrimaryText
					fontSize={16}
					fontWeight={500}
				>
					{dollarFormatter.format(totalDonations)}
				</PrimaryText>
			</TableCell>
			<TableCell>
				<IconButton onClick={onInactiveLink}>
					<FiTrash2 color={COLORS.primary.error} />
				</IconButton>
			</TableCell>
		</TableRow>
	);
}
