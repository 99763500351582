import { Box } from "@mui/material";
import { Cause } from "components/CausesSkills/SelectCauses";
import EditableAddress from "components/Common/EditableAddress";
import MuiEditButton from "components/Common/MuiEditButton";
import { PrimaryText } from "components/Common/PrimaryText";
import { QnAEdit } from "components/Common/QnAEdit";
import DonationInputs from "components/Inputs/DonationInputs";
import SelectCausesModal from "components/Modals/SelectCausesModal";
import UploadPhotoModal from "components/Modals/UploadPhotoModal";
import EditablePic from "components/Pics/EditablePic";
import { COLORS } from "constants/Colors";
import { causes } from "constants/Data";
import { useOrganization } from "context/OrganizationContext";
import React, { useState } from "react";
import {
	updateOrganization,
	uploadPhoto
} from "services/organizations-service";
import validate from "utils/validation";

export function Settings() {
	const { selectedOrg } = useOrganization();
	const [editing, setEditing] = useState();
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState({});

	const url = `https://dev.donate.rayzeapp.com/orgs/${selectedOrg?.id}`;

	const baseUrl = "https://dev.donate.rayzeapp.com/";

	const handleSave = async (key, value) => {
		try {
			if (["causes", "address", "customDonationAmounts"].includes(key))
				console.log("Doesn't required validation.");
			else if (key === "adminEmail")
				validate({ value, type: "email", name: key });
			else if (key === "website") validate({ value, type: "url", name: key });
			else if (key === "theme.primaryColor") {
				validate({ value, type: "color", name: key });
			} else if (key === "theme.secondaryColor")
				validate({ value, type: "color", name: key });
			else validate({ value, type: key });

			if (value === "Yes" || value === "No") value = value === "Yes";

			let data = {
				[key]: value
			};

			if (key === "address") data = { ...value };

			setLoading(true);

			updateOrganization(
				selectedOrg,
				data,
				key === "coverPhotos" ? [value] : [],
				[],
				onSuccessUpdate
			);
		} catch (err) {
			if (err?.name) setErrors({ [err?.name]: err.message });
			console.log(err);
		}
	};

	const onSuccessUpdate = () => {
		if (errors) setErrors(null);
		setLoading(false);
		setEditing(null);
	};

	// Handle upload cover, profile and logo images
	const handleUpload = (image, type) => {
		setLoading(true);
		uploadPhoto({ orgId: selectedOrg?.id, imageUrl: image, type })
			.then((result) => console.log(result))
			.catch((err) => console.log(err))
			.finally((_) => {
				setLoading(false);
				setEditing(null);
			});
	};

	const handleCancel = () => {
		setEditing(null);
		if (errors) setErrors(null);
	};

	return (
		<Box
			width="100%"
			minHeight="80lvh"
			pl="26px"
			pt="20px"
			pb={100}
		>
			<PrimaryText
				fontSize={24}
				fontWeight={400}
			>
				Profile Settings
			</PrimaryText>

			<Box
				display="flex"
				flexDirection="row"
				alignItems="center"
				mt={18}
			>
				<EditablePic
					imageSrc={selectedOrg?.coverPhoto}
					label="Cover Photo"
					onChange={() => setEditing("coverPhoto")}
				/>
				<EditablePic
					imageSrc={selectedOrg?.profilePic}
					label="Profile Photo"
					boxProps={{ ml: 80 }}
					square
					onChange={() => setEditing("profilePhoto")}
				/>
			</Box>

			<Box
				display="flex"
				flexDirection="row"
				mt={25}
			>
				{/* Left */}
				<Box
					display="flex"
					flexDirection="column"
				>
					<QnAEdit
						required={true}
						type="text"
						question="Organization Name"
						answer={selectedOrg?.name}
						property="name"
						onEdit={(property) => setEditing(property)}
						editing={editing}
						onSave={handleSave}
						onCancel={handleCancel}
						errorMessage={errors?.name}
					/>

					<EditableAddress
						required={true}
						title="Mailing Address (Will be displayed on map)"
						selectedAddress={selectedOrg?.address}
						property="address"
						onEdit={(property) => setEditing(property)}
						editing={editing}
						onSave={handleSave}
						onCancel={handleCancel}
					/>

					<QnAEdit
						required={true}
						type="text"
						question="Admin Email"
						answer={selectedOrg?.adminEmail}
						property="adminEmail"
						onEdit={(property) => setEditing(property)}
						editing={editing}
						onSave={handleSave}
						onCancel={handleCancel}
						errorMessage={errors?.adminEmail}
					/>

					<QnAEdit
						required={true}
						type="text"
						question="Phone Number"
						answer={selectedOrg?.phone}
						property="phone"
						onEdit={(property) => setEditing(property)}
						editing={editing}
						onSave={handleSave}
						onCancel={handleCancel}
						errorMessage={errors?.phone}
					/>

					<DonationInputs
						isEditing={editing}
						onEdit={(property) => setEditing(property)}
						property="customDonationAmounts"
						onCancel={handleCancel}
						onSave={handleSave}
						donations={selectedOrg?.customDonationAmounts}
					/>
				</Box>
				{/* Right */}
				<Box
					display="flex"
					flexDirection="column"
					ml={50}
				>
					<QnAEdit
						required={true}
						type="text"
						question="Employee Identification Number (EIN)"
						answer={selectedOrg?.ein}
						property="ein"
						onEdit={(property) => setEditing(property)}
						editing={editing}
						onSave={handleSave}
						onCancel={handleCancel}
						errorMessage={errors?.ein}
					/>

					<QnAEdit
						required={true}
						type="desc"
						question="About (500 character max)"
						answer={selectedOrg?.about}
						property="about"
						onEdit={(property) => setEditing(property)}
						editing={editing}
						onSave={handleSave}
						onCancel={handleCancel}
						maxWidth={500}
					/>

					<QnAEdit
						required={true}
						type="text"
						question="Website URL"
						answer={selectedOrg?.website}
						property="website"
						onEdit={(property) => setEditing(property)}
						editing={editing}
						onSave={handleSave}
						onCancel={handleCancel}
						errorMessage={errors?.website}
					/>

					{/* Causes */}

					<PrimaryText
						fontSize={17}
						fontWeight={600}
					>
						Causes (Limit 5)
					</PrimaryText>

					<Box
						display="flex"
						flexDirection="row"
						alignItems="flex-start"
						mt={12}
						gap={16}
					>
						{selectedOrg?.causes?.map((causeValue, index) => {
							
							let causeObj = causes.find((cause) => cause.value === causeValue);
							if (!causeObj) {
								causeObj = causes.find((cause) => cause.rayze === causeValue);
							}
							if (!causeObj) return null;

							return (
								<Cause
									key={index}
									{...causeObj}
									isActive={true}
								/>
							);
						})}
					</Box>
					<MuiEditButton onChange={() => setEditing("causes")} />
				</Box>
			</Box>

			<Box
				width="70%"
				height={3}
				bgcolor={COLORS.primary.black}
				my={40}
			/>

			<PrimaryText fontSize={24}>Direct Donation Theme Settings</PrimaryText>

			{/* Brand */}
			<Box
				display="flex"
				flexDirection="row"
				mt={16}
			>
				<Box>
					<QnAEdit
						required={false}
						type="color"
						question="Primary Brand Color"
						answer={selectedOrg?.theme?.primaryColor || COLORS.primary.lightBlue}
						property="theme.primaryColor"
						onEdit={(property) => setEditing(property)}
						editing={editing}
						onSave={handleSave}
						onCancel={handleCancel}
						errorMessage={errors?.primaryColor}
					/>
					<QnAEdit
						required={false}
						type="color"
						question="Header Background Color"
						answer={selectedOrg?.theme?.secondaryColor || COLORS.primary.darkBlue}
						property="theme.secondaryColor"
						onEdit={(property) => setEditing(property)}
						editing={editing}
						onSave={handleSave}
						onCancel={handleCancel}
						errorMessage={errors?.theme?.secondaryColor}
					/>
				</Box>

				<Box ml={100}>
					<EditablePic
						imageSrc={selectedOrg?.theme?.logo}
						label="Logo"
						freeform={true}
						// square={true}
						onChange={() => setEditing("theme.logo")}
					/>
				</Box>
			</Box>

			{/* Social Links */}

			<Box
				display="flex"
				flexDirection="column"
			>
				<QnAEdit
					required={false}
					type="text"
					question="Facebook"
					answer={selectedOrg?.facebook || "https://www.facebook.com/"}
					property="facebook"
					onEdit={(property) => setEditing(property)}
					editing={editing}
					onSave={handleSave}
					onCancel={handleCancel}
					errorMessage={errors?.facebook}
				/>
				<QnAEdit
					required={false}
					type="text"
					question="Instagram"
					answer={selectedOrg?.instagram || "https://www.instagram.com/"}
					property="instagram"
					onEdit={(property) => setEditing(property)}
					editing={editing}
					onSave={handleSave}
					onCancel={handleCancel}
					errorMessage={errors?.instagram}
				/>
				<QnAEdit
					required={false}
					type="text"
					question="LinkedIn"
					answer={selectedOrg?.linkedin || "https://www.linkedin.com/"}
					property="linkedin"
					onEdit={(property) => setEditing(property)}
					editing={editing}
					onSave={handleSave}
					onCancel={handleCancel}
					errorMessage={errors?.linkedin}
				/>
				<QnAEdit
					required={false}
					type="text"
					question="X (formerly Twitter)"
					answer={selectedOrg?.twitter || "https://twitter.com/"}
					property="twitter"
					onEdit={(property) => setEditing(property)}
					editing={editing}
					onSave={handleSave}
					onCancel={handleCancel}
					errorMessage={errors?.twitter}
				/>
			</Box>

			<Box
				display="flex"
				flexDirection="column"
				mt={34}
			>
				<PrimaryText
					fontSize={24}
					fontWeight={400}
				>
					View Direct Donation Link
				</PrimaryText>

				<a
					href={selectedOrg?.customUrl ? baseUrl + selectedOrg?.customUrl : url}
					target="_blank"
					rel={"noreferrer"}
				>
					<PrimaryText
						fontSize={16}
						fontWeight={400}
						style={{ color: COLORS.primary.lightBlue, marginTop: 7 }}
					>
						{selectedOrg?.customUrl ? baseUrl + selectedOrg?.customUrl : url}
					</PrimaryText>
				</a>
			</Box>

			{editing === "coverPhoto" && (
				<UploadPhotoModal
					onCancel={handleCancel}
					isOpen={true}
					title="Cover Photo"
					onSave={(image) => handleUpload(image, "cover")}
					loading={loading}
				/>
			)}
			{editing === "profilePhoto" && (
				<UploadPhotoModal
					onCancel={handleCancel}
					isOpen={true}
					square={true}
					title="Profile Photo"
					onSave={(image) => handleUpload(image, "profile")}
					loading={loading}
				/>
			)}
			{editing === "theme.logo" && (
				<UploadPhotoModal
					onCancel={handleCancel}
					isOpen={true}
					// square={true}
					freeform={true}
					title="Logo"
					onSave={(image) => handleUpload(image, "theme.logo")}
					loading={loading}
				/>
			)}

			{editing === "causes" && (
				<SelectCausesModal
					isOpen={true}
					onCancel={() => setEditing(null)}
					onSave={(selectedCauses) => handleSave("causes", selectedCauses)}
					causes={selectedOrg?.causes}
					loading={loading}
				/>
			)}
		</Box>
	);
}
