import { CATEGORIES } from "assets/categories";
import { Links } from "./Links";

export const ROLES = [
	{
		value: "Leader",
		label: "Admin"
	},
	{
		value: "Employee",
		label: "User"
	}
];

export const POSITIONS = [
	{
		value: "staff",
		label: "Staff"
	},
	{
		value: "volunteer",
		label: "Volunteer"
	},
	{
		value: "board",
		label: "Board"
	}
];

export const DONATION_STATUSES = [
	{
		value: 0,
		label: "Awaiting Approval"
	},
	{
		value: 1,
		label: "Approved"
	},
	{
		value: 2,
		label: "Denied"
	}
];

export const TOP_CAUSE_COLORS = [
	"linear-gradient(270.04deg, #1DA1F2 0.03%, #46D7F7 98.22%)",
	"linear-gradient(270deg, #FFE000 0%, #FFF84D 100%)",
	"linear-gradient(269.89deg, #F35A3B 0.1%, #FFA572 97.04%)",
	"linear-gradient(270deg, #EE1D52 0%, #F55CA6 100%)",
	"linear-gradient(270deg, #98D15E 0%, #9BF2A4 100%)"
];

export const MONTHS = [
	{
		value: "January",
		label: "January"
	},
	{
		value: "February",
		label: "February"
	},
	{
		value: "March",
		label: "March"
	},
	{
		value: "April",
		label: "April"
	},
	{
		value: "May",
		label: "May"
	},
	{
		value: "June",
		label: "June"
	},
	{
		value: "July",
		label: "July"
	},
	{
		value: "August",
		label: "August"
	},
	{
		value: "September",
		label: "September"
	},
	{
		value: "October",
		label: "October"
	},
	{
		value: "November",
		label: "November"
	},
	{
		value: "December",
		label: "December"
	}
];

export const TIPS = {
	sidebar: {
		title: "This is your sidebar",
		description: (
			<>
				Here you can access all the different <b>pages</b> of your companies
				site.
			</>
		),
		nextPath: Links.BusinessDashboard
	},
	[Links.BusinessDashboard]: {
		title: "Dashboard",
		description: (
			<>
				Shows your an <b>overview</b> of your companies giving trends and
				employee involvement
			</>
		),
		nextPath: Links.BusinessDonations
	},
	[Links.BusinessDonations]: {
		title: "Donations",
		description: (
			<>
				Let’s you see all donations <b>employees</b> or the company have made as
				well as any donations that are pending.
			</>
		),
		nextPath: Links.BusinessCampaigns
	},
	[Links.BusinessCampaigns]: {
		title: "Campaigns",
		description: (
			<>
				This is where you will set up <b>giving campaigns</b> for your
				employees. Here you can determine what <b>causes</b> the campaign will
				support and how much it is looking to <b>raise</b>.
			</>
		),
		nextPath: Links.BusinessEmployees
	},
	[Links.BusinessEmployees]: {
		title: "Employees",
		description: (
			<>
				<b>Invite</b> new employees and set up their <b>profile</b> for
				corporate matching.
			</>
		),
		nextPath: Links.GeneralSettings
	},
	[Links.GeneralSettings]: {
		title: "Settings",
		description: (
			<>
				This is where you can determine <b>admin</b> settings as well as default
				campaign settings like <b>supported nonprofits</b> and
				<b>employee match</b> amount.
			</>
		),
		nextPath: Links.BusinessDashboard,
		buttonTitle: "Finish",
		hideIcon: true
	},

	// Nonprofit and community group Sidebar Tips
	[Links.Dashboard]: {
		title: "Dashboard",
		description: (
			<>
				Shows you an <b>overview</b> of your nonprofits information and
				fundraising
			</>
		),
		nextPath: Links.Team
	},
	[Links.Team]: {
		title: "Team",
		description: (
			<>
				<b>Invite</b> new team members and set up their <b>position</b> and
				<b> information</b>
			</>
		),
		nextPath: Links.Events
	},
	[Links.Events]: {
		title: "Events",
		description: (
			<>
				This is where you will set up <b>events</b> for volunteers to attend.
				Here you can determine the <b>date, time, and location</b> of each event
				you wish to run.
			</>
		),
		nextPath: Links.Donations
	},
	[Links.Donations]: {
		title: "Donations",
		description: (
			<>
				Let’s you see all donations <b>individual</b> donors or <b>companies</b>{" "}
				have made if it’s a recurring or one-time donation.
			</>
		),
		nextPath: Links.Settings
	},
	[Links.Settings]: {
		title: "General Settings",
		description: (
			<>
				This is where you can update <b>information</b> about your nonprofit as
				well as update your <b>profile or cover</b> photo.
			</>
		),
		nextPath: Links.Verification
	},
	[Links.Verification]: {
		title: "Verification",
		description: (
			<>
				Here you can <b>upload</b> documents for the{" "}
				<b>Rayze verification process</b>
				and check the status of your verification.
			</>
		),
		nextPath: Links.Dashboard,
		buttonTitle: "Finish",
		hideIcon: true
	}
};

export const COMMUNITY_TIPS = {
	sidebar: {
		title: "This is your sidebar",
		description: (
			<>
				Here you can access all the different <b>pages</b> of your companies
				site.
			</>
		),
		nextPath: Links.Dashboard
	},
	[Links.Dashboard]: {
		title: "Dashboard",
		description: (
			<>
				Shows you an <b>overview</b> of your community group information and
				fundraising
			</>
		),
		nextPath: Links.Team
	},
	[Links.Team]: {
		title: "Team",
		description: (
			<>
				<b>Invite</b> new team members and set up their <b>position</b> and
				<b> information</b>
			</>
		),
		nextPath: Links.Events
	},
	[Links.Events]: {
		title: "Events",
		description: (
			<>
				This is where you will set up <b>events</b> for volunteers to attend.
				Here you can determine the <b>date, time, and location</b> of each event
				you wish to run.
			</>
		),
		nextPath: Links.Settings
	},
	[Links.Settings]: {
		title: "General Settings",
		description: (
			<>
				This is where you can update <b>information</b> about your community group as
				well as update your <b>profile or cover</b> photo.
			</>
		),
		nextPath: Links.Dashboard,
		buttonTitle: "Finish",
		hideIcon: true
	}
};

export const creditCardTypes = [
	{
		name: "Visa",
		id: "visa",
		fontAwesome: "cc-visa"
	},
	{
		name: "MasterCard",
		id: "mastercard",
		fontAwesome: "cc-mastercard"
	},
	{
		name: "American Express",
		id: "amex",
		fontAwesome: "cc-amex"
	},
	{
		name: "Discover",
		id: "discover",
		fontAwesome: "cc-discover"
	},
	{
		name: "Diners Club",
		id: "diners",
		fontAwesome: "cc-diners-club"
	},
	{
		name: "Paypal",
		id: "paypal",
		fontAwesome: "cc-paypal"
	},
	{
		name: "JCB",
		id: "jcb",
		fontAwesome: "cc-jcb"
	}
];

export const causes = [
	{
		name: "Advocacy & Human Rights",
		icon: CATEGORIES.advocacyAndHumanRights,
		value: "advocacyAndHumanRights",
		rayze: "lRIVTVELNV4a5a17jwBF"
	},
	{ name: "Animals", icon: CATEGORIES.animals, value: "animals" },
	{
		name: "Arts & Culture",
		icon: CATEGORIES.artsAndCulture,
		value: "artsAndCulture",
		rayze: "byuXDG8tlkK3hyE08gr3"
	},
	{
		name: "Board Development",
		icon: CATEGORIES.boardDevelopment,
		value: "boardDevelopment",
		rayze: "B65zjcqauZzcpKa4rkis"
	},
	{
		name: "Children & Youth",
		icon: CATEGORIES.childrenAndYouth,
		value: "childrenAndYouth"
	},
	{ name: "Community", icon: CATEGORIES.community, value: "community" },
	{
		name: "Computers & Technology",
		icon: CATEGORIES.computersAndTechnology,
		value: "computersAndTechnology"
	},
	{
		name: "Crisis Support",
		icon: CATEGORIES.crisisSupport,
		value: "crisisSupport"
	},
	{ name: "Disabled", icon: CATEGORIES.disabled, value: "disabled" },
	{
		name: "Disaster Relief",
		icon: CATEGORIES.disasterRelief,
		value: "disasterRelief"
	},
	{
		name: "Education & Literacy",
		icon: CATEGORIES.educationAndLiteracy,
		value: "educationAndLiteracy"
	},
	{
		name: "Emergency & Safety",
		icon: CATEGORIES.emergencyAndSafety,
		value: "emergencyAndSafety"
	},
	{
		name: "Employment",
		icon: CATEGORIES.employment,
		value: "employment"
	},
	{
		name: "Environment",
		icon: CATEGORIES.environment,
		value: "environment"
	},
	// {
	// 	name: "LGBTQ+",
	// 	icon: CATEGORIES.gayLesbianBiTrans,
	// 	value: "gayLesbianBiTrans"
	// },
	{
		name: "Health & Medicine",
		icon: CATEGORIES.healthAndMedicine,
		value: "healthAndMedicine"
	},
	{
		name: "Homeless & Housing",
		icon: CATEGORIES.homelessAndHousing,
		value: "homelessAndHousing"
	},
	{ name: "Hunger", icon: CATEGORIES.hunger, value: "hunger" },
	{
		name: "Immigrants & Refugees",
		icon: CATEGORIES.immigrantsAndRefugees,
		value: "immigrantsAndRefugees"
	},
	{
		name: "International",
		icon: CATEGORIES.international,
		value: "international"
	},
	{
		name: "Justice & Legal",
		icon: CATEGORIES.justiceAndLegal,
		value: "justAndLegal"
	},
	{
		name: "Media & Broadcasting",
		icon: CATEGORIES.mediaAndBroadcasting,
		value: "mediaAndBroadcasting"
	},
	{ name: "Politics", icon: CATEGORIES.politics, value: "politics" },
	{
		name: "Race & Ethnicity",
		icon: CATEGORIES.raceAndEthnicity,
		value: "raceAndEthnicity"
	},
	{ name: "Religion", icon: CATEGORIES.religion, value: "religion" },
	{ name: "Seniors", icon: CATEGORIES.seniors, value: "seniors" },
	{
		name: "Sports & Recreation",
		icon: CATEGORIES.sportsAndRecreation,
		value: "sportsAndRecreation"
	},
	{
		name: "Veterans & Military Families",
		icon: CATEGORIES.veteransAndMilitaryFamilies,
		value: "veteransAndMilitaryFamilies"
	},
	{ name: "Women", icon: CATEGORIES.women, value: "women" }
];
